<script setup lang="ts">
import { FormDesignItemDto, GridExcelDto, RowGridExcelDto, DisplayType } from '@/core/store/survey/surveyDesignDtos';
import { SurveyResponseItemDto } from '@/core/store/survey/surveyResponseDtos';
import Survey from '@/core/store/survey/surveyStore';
import { computed, CSSProperties } from 'vue';

const {
  displayMode,
  isDesign,
  state,
  getNewTag,
} = Survey();


const props = defineProps({
  item: {
    type: Object as () => FormDesignItemDto,
    required: true
  },
  labelStyle: {
    type: Object as () => CSSProperties,
    required: true
  },
  response: {
    type: Object as () => SurveyResponseItemDto,
    default: null,
    required: false
  }
});

// Computed properties para la grilla
const gridData = computed(() => {
  if (!props.item.gridDesign) {
    props.item.gridDesign = new GridExcelDto();
  }
  
  return props.item.gridDesign;
});

// Computed property para los datos de respuesta
const responseData = computed(() => {
  if (!props.response?.value) {
    // Si no hay datos de respuesta, creamos un array vacío con la estructura correcta
    return Array(gridData.value.rows.length).fill(null).map(() => 
      Array(gridData.value.headers.length).fill(0)
    );
  }
  return props.response.value;
});

const columnHeaders = computed(() => {
  return gridData.value.headers;
});

const rowHeaders = computed(() => {
  return gridData.value.rows.map(row => row.title);
});

// Computed property para el número total de columnas
const totalColumns = computed(() => {
  return columnHeaders.value.length;
});

// Método para generar array de índices de columnas
const columnIndices = computed(() => {
  return Array.from({ length: totalColumns.value }, (_, i) => i);
});

// Métodos para manejar la grilla
const updateCell = (rowIndex: number, colIndex: number, value: string) => {
  if (!gridData.value.rows[rowIndex]) {
    gridData.value.rows[rowIndex] = new RowGridExcelDto();
  }
  // Eliminamos espacios al inicio y final antes de guardar
  gridData.value.rows[rowIndex].tags[colIndex] = value.trim();
};

const getDefaultTag = (rowIndex: number, colIndex: number) => {
  return getNewTag();
}

const validateCellTagValue = (value: string, rowIndex: number, colIndex: number): boolean => {
  return Survey().validTag(value, props.item.id+"."+rowIndex+"."+colIndex);
};

const initializeCell = (rowIndex: number, colIndex: number) => {
  if (!gridData.value.rows[rowIndex]) {
    gridData.value.rows[rowIndex] = new RowGridExcelDto();
  }
  if (!gridData.value.rows[rowIndex].tags[colIndex]) {
    gridData.value.rows[rowIndex].tags[colIndex] = getDefaultTag(rowIndex, colIndex);
  }
};

const getCellValue = (rowIndex: number, colIndex: number) => {
  initializeCell(rowIndex, colIndex);
  // Aseguramos que siempre retornamos el valor sin espacios
  return gridData.value.rows[rowIndex].tags[colIndex].trim();
};

let previousValue: string | null = null;

const onCellFocus = (rowIndex: number, colIndex: number) => {
  // Guardamos el valor actual antes de que el usuario lo edite
  previousValue = gridData.value.rows[rowIndex].tags[colIndex];
};

const onCellBlur = (rowIndex: number, colIndex: number) => {
  const currentValue = gridData.value.rows[rowIndex].tags[colIndex];
  
  // Comparamos los valores sin espacios al inicio y final
  if (currentValue.trim() === previousValue?.trim()) {
    // Si solo cambió espacios, guardamos el valor sin espacios
    updateCell(rowIndex, colIndex, currentValue.trim());
    return;
  }
  
  if (!currentValue || currentValue.trim() === '') {
    // Si está vacío, mantenemos el valor que ya tenía
    updateCell(rowIndex, colIndex, previousValue || getCellValue(rowIndex, colIndex));
    return;
  }

  if (!validateCellTagValue(currentValue.trim(), rowIndex, colIndex)) {
    // Si no es válido, restauramos el valor anterior
    updateCell(rowIndex, colIndex, previousValue || getCellValue(rowIndex, colIndex));
    return;
  }

  // Si llegamos aquí, el valor es válido y diferente al anterior
  previousValue = currentValue.trim();
};

const addRow = () => {
  if (isDesign.value) {
    const newRow = new RowGridExcelDto();
    newRow.title = `Fila ${gridData.value.rows.length + 1}`;
    // Inicializamos las celdas de la nueva fila
    newRow.tags = Array(totalColumns.value).fill('').map((_, i) => getDefaultTag(gridData.value.rows.length, i));
    gridData.value.rows.push(newRow);
  }
};

const addColumn = () => {
  if (isDesign.value) {
    const newColIndex = gridData.value.headers.length;
    gridData.value.headers.push(`Columna ${newColIndex + 1}`);
    // Inicializamos las celdas de la nueva columna
    gridData.value.rows.forEach((row, rowIndex) => {
      row.tags.push(getDefaultTag(rowIndex, newColIndex));
    });
  }
};

const removeRow = (index: number) => {
  if (isDesign.value) {
    gridData.value.rows.splice(index, 1);
  }
};

const removeColumn = (index: number) => {
  if (isDesign.value) {
    gridData.value.headers.splice(index, 1);
    gridData.value.rows.forEach(row => {
      row.tags.splice(index, 1);
    });
  }
};

// Métodos para manejar las cabeceras
const updateColumnHeader = (index: number, value: string) => {
  if (isDesign.value) {
    gridData.value.headers[index] = value;
  }
};

const updateRowHeader = (index: number, value: string) => {
  if (isDesign.value) {
    gridData.value.rows[index].title = value;
  }
};

// Método para actualizar los datos de respuesta
const updateResponseData = (rowIndex: number, colIndex: number, value: string) => {
  if (!props.response) return;
  
  // Convertimos el valor a número, permitiendo decimales
  const numValue = parseFloat(value) || 0;
  
  // Aseguramos que el array existe
  if (!props.response.value) {
    props.response.value = Array(gridData.value.rows.length).fill(null).map(() => 
      Array(gridData.value.headers.length).fill(0)
    );
  }
  
  // Actualizamos el valor
  props.response.value[rowIndex][colIndex] = numValue;
};
</script>

<template>
  <div class="excel-grid-container">
    <div class="excel-grid-wrapper">
      <div class="excel-grid">
        <!-- Encabezados de columnas -->
        <div class="grid-header-row">
          <div class="grid-corner"></div>
          <div v-for="(header, index) in columnHeaders" 
               :key="'col-' + index" 
               class="grid-header-cell">
            <input v-if="isDesign && displayMode === DisplayType.EDIT"
                   type="text"
                   :value="header"
                   :style="props.labelStyle"
                   @input="(e: Event) => updateColumnHeader(index, (e.target as HTMLInputElement).value)"
                   class="header-input" />
            <span v-else   :style="props.labelStyle">
              {{ header  }}
            </span>
            <img v-if="isDesign && displayMode === DisplayType.EDIT" 
                    class="btn-icon delete-btn"
                    src="/assets/objectButtonsIcons/delcol.svg"
                    style="height: 16px; width: 30px;"
                    @click="removeColumn(index)"
                    title="Eliminar columna" />
          </div>
        </div>
        
        <!-- Filas de datos -->
        <div v-for="(row, rowIndex) in gridData.rows" 
             :key="'row-' + rowIndex" 
             class="grid-row">
          <div class="grid-row-header">
            <input v-if="isDesign && displayMode === DisplayType.EDIT"
                   type="text"
                   :style="props.labelStyle"
                   :value="row.title"
                   @input="(e: Event) => updateRowHeader(rowIndex, (e.target as HTMLInputElement).value)"
                   class="header-input" />
            <span  v-else :style="props.labelStyle">{{ row.title  }}</span>
            <img v-if="isDesign && displayMode === DisplayType.EDIT" 
                    class="btn-icon delete-btn"
                    @click="removeRow(rowIndex)"
                    title="Eliminar fila"
                    src="/assets/objectButtonsIcons/delrow.svg" />
          </div>
          
          <div v-for="(tag, colIndex) in row.tags" 
               :key="'cell-' + rowIndex + '-' + colIndex" 
               class="grid-cell">
            <div class="cell-preview">
              <template v-if="isDesign">
                <input v-if="displayMode === DisplayType.EDIT"
                       type="text"
                       v-model="gridData.rows[rowIndex].tags[colIndex]"
                       @focus="() => onCellFocus(rowIndex, colIndex)"
                       @blur="() => onCellBlur(rowIndex, colIndex)"
                       class="cell-input" />
                <span v-else>{{ gridData.rows[rowIndex].tags[colIndex] }}</span>
              </template>
              <template v-else>
                <input v-if="displayMode === DisplayType.EDIT"
                       type="number"
                       step="any"
                       :value="responseData[rowIndex][colIndex]"
                       @input="(e: Event) => updateResponseData(rowIndex, colIndex, (e.target as HTMLInputElement).value)"
                       class="cell-input numeric-input" />
                <span v-else class="numeric-value">{{ responseData[rowIndex][colIndex] }}</span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-controls" v-if="isDesign && displayMode === DisplayType.EDIT">
      <button class="btn-primary" @click="addRow">
        <img src="/assets/objectButtonsIcons/addrow.svg" style="width: 24px;" alt="Añadir Fila" />
        Añadir Fila
      </button>
      <button class="btn-primary" @click="addColumn">
        <img src="/assets/objectButtonsIcons/addcol.svg" style="height: 16px;" alt="Añadir Columna" />
        Añadir Columna
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.excel-grid-container {
  width: 100%;
  background: white;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.excel-grid-wrapper {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #D3EDE6;
  border-radius: 4px;
}

.excel-grid {
  width: max-content;
  min-width: 100%;
}

.grid-header-row {
  display: flex;
  background-color: #f8f9fa;
  border-bottom: 1px solid #D3EDE6;
}

.grid-corner {
  width: 120px;
  min-width: 120px;
  flex-shrink: 0;
  border-right: 1px solid #D3EDE6;
  background-color: #f8f9fa;
  position: sticky;
  left: 0;
  z-index: 2;
}

.grid-header-cell {
  flex: 1;
  min-width: 120px;
  padding: 0.75rem;
  border-right: 1px solid #D3EDE6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: #126192;
}

.grid-row {
  display: flex;
  border-bottom: 1px solid #D3EDE6;

  &:last-child {
    border-bottom: none;
  }
}

.grid-row-header {
  width: 120px;
  min-width: 120px;
  flex-shrink: 0;
  padding: 0.75rem;
  border-right: 1px solid #D3EDE6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  position: sticky;
  left: 0;
  z-index: 1;
  color: #126192;
}

.grid-cell {
  flex: 1;
  min-width: 120px;
  padding: 0.5rem;
  border-right: 1px solid #D3EDE6;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    border-right: none;
  }
}

.header-input {
  flex: 1;
  border: none;
  background: transparent;
  color: inherit;
  font-size: 0.875rem;
  padding: 0.25rem;
  
  &:hover, &:focus {
    background: #f5f5f5;
    outline: none;
    border-radius: 4px;
  }
}

.cell-preview {
  width: 100%;
  background-color: #D3EDE6;
  padding: 0.5rem;
  border-radius: 4px;
  text-align: center;
  color: #126192;

  input {
    &[value^="Tag"] {
      color: rgba(18, 97, 146, 0.5);
    }

    &:focus {
      color: #126192;
    }
  }
}

.grid-controls {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0;
}

.btn-primary {
  background-color: #126192;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;

  &:hover {
    background-color: darken(#126192, 10%);
  }
}

.btn-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #666;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  
  &:hover {
    background-color: #ff4444;
    color: white;
  }
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #D3EDE6;
  border-radius: 4px;
  
  &:hover {
    background: darken(#D3EDE6, 10%);
  }
}

.cell-input {
  width: 100%;
  border: none;
  background: transparent;
  color: #126192;
  font-size: 0.875rem;
  text-align: center;
  
  &:hover, &:focus {
    background: #f5f5f5;
    outline: none;
    border-radius: 4px;
    color: #126192;
  }

  &.numeric-input {
    text-align: right;
    padding-right: 0.5rem;
  }
}

.numeric-value {
  text-align: right;
  padding-right: 0.5rem;
  display: block;
  width: 100%;
}
</style> 