<script setup lang="ts">
import { Sh } from '@/core/sharedService';
import { UserListItemDto } from '@/core/store/user/dtos';
import useUsers from '@/core/store/user/useUsers';
import { roles } from '@/shared/globaEnums';
import DataTablesCore, { ConfigColumns } from 'datatables.net';
import DataTable from 'datatables.net-vue3';
import { useRoute } from 'vue-router';
import {optionsTable as commonOptionsTable} from '../common';
import BadgeComponent from "../components/badge.vue";
import userIcon from "../../assets/user-icon.svg";
import plusButton from "../components/plusButton.vue";
// Definición de las props

DataTable.use(DataTablesCore);
const route = useRoute();
const {user, list, userList} = useUsers();
list();


const newEntity = () => {

  Sh.router.push(`/users/new`)
}

const Actions = (param1:any, param2:any, param3: UserListItemDto) =>  {
  const html = `
    <a href="/users/${param3.id}"><img class="actionIcon" src="/assets/eye_action_icon.png" alt="eye_icon"></a>
    <a href="/users/edit/${param3.id}"><img class="actionIcon" src="/assets/pen_action_icon.png" alt="pen_icon"></a> 
    
  `;
  return html;
}
const roleRender = (param1:any, param2:any, param3: UserListItemDto) => {
  switch(param3.role){
    case roles.ROOT:
      return "Root";
    case roles.CLIENT:
      return "Cliente";
    case roles.GROUP:
      return "Grupo de Entidades";
    case roles.ENTITY:
    case roles.USER:
      return "Entidad";
  }
  return "";
}
const renderMail = (param1:any, param2:any, param3: UserListItemDto) => {
  return `<a style="color: ${param3.status!== null && param3.status ==='INACTIVE' ? 'red' : 'black'}" href="mailto:${param3.email}">${param3.email}</a>`;
}
const renderFullName = (param1:any, param2:any, param3: UserListItemDto) => {
  return `${param3.name} ${param3.surname1}`;
}
const columns:ConfigColumns[] = [
  { data: 'email', render: renderMail, width: '16%'},  
  { data: null, render: renderFullName, width: '16%' },
  { data: 'role', render: roleRender, width: '12%' },
  { data: 'clientName', width: '15%' },
  { data: 'groupName',  width: '16%'  },
  { data: 'entityName', width: '16%' },
  { render: Actions, orderable: false, width: '9%'}
];

const optionsTable = {
  ...commonOptionsTable,
  pageLength: 5,
  // lengthMenu: [5, 10, 15, 20],
  columns: columns,
};

</script>

<template>
  <div class="container-client">
    <div class="header-container">
      <BadgeComponent :icon="userIcon" label="Usuarios" />
      <plusButton label="Nuevo Usuario" @click="newEntity()"/>
    </div>
    <div class="table-responsive">
      <DataTable :options="optionsTable" :data="userList" :columns="columns" width="100%">
        <thead>
          <tr>
            <th>Email</th>
            <th>Nombre</th>
            <th>Role</th>
            <th>Cliente</th>
            <th>Grupo</th>
            <th>Entidad</th>
            <th class="actionsTh"></th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
</template>
<style scoped>

.dataTables_length {
  display: none;
}

* {
  background-color: #fff;
}


.container-client {
  padding: 50px;
  border-radius: 22px;
  max-width: 80%;
  width: 100%;
  margin: 0px auto;
  box-shadow: 0px 0px 15px #0000002e;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.table-responsive {
  width: 100%;
  text-align: left;
}

.table-responsive th {

  padding: 5px;
  text-align: left;
}

.actionsTh {
  display: flex;
  flex-direction: row;
  min-width: 90px;
}
</style>
@/core/sharedService