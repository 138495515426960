<template>
  <DPDialog :show="show" :closeLostFocus="true" :onClose="closeDialog">
    <div class="formulaCreatorContainer">
      <!-- HEADER -->
      <div class="formulaModalHeaderContainer">
        <h3 class="modalFormulaTitle">Fórmula</h3>
        <button class="close-button" @click="closeDialog">
          <span class="x-mark">&#10005;</span>
        </button>
      </div>
      <div class="formulaSelector">
        <!-- CAMPOS -->
        <div class="formulaSelectorFields">
          <label class="selectorHeader">Lista de campos</label>
          <div class="fieldsListContainer">
            <div class="input-icon-container">
              <i class="bi bi-search search-icon"></i>
              <input v-model="searchTerm" type="text" placeholder="Buscar campo..." class="filterInput" />
            </div>
            <div class="itemContainer" v-for="(i, index) in filteredFormulaAssignable" :key="index"
              @click="selectedItemFormula = i" :class="{ selectedItems: isTagInFormula(i.tag) }">
              <div class="itemLabelContainer">
                <span class="itemLabel" v-scroll-if-needed>{{ i.label }}</span>
              </div>
              <span class="itemTag">{{ i.tag }}</span>
            </div>
          </div>
        </div>
        <!-- VALORES DEL CAMPO -->
        <div class="formulaSelectorValues">
          <label class="selectorHeader">Valores del campo <span v-if="selectedItemFormula" class="tagSpan">{{
              selectedItemFormula.tag }}</span></label>

          <div class="valuesListContainer">
            <!-- Botones para el item principal -->
             <div v-if="selectedItemFormula && selectedItemFormula.type !== FieldType.EXCELGRID">
              <button
                v-for="(value, index) in fieldValues"
              :key="index"
              class="valueButton"
              @click="addToFormula(value)"
              :class="{
                pesoUsed: value === 'PESO' && isTagPesoInFormula(),
                valorUsed: value === 'VALOR' && isTagValueInFormula()
              }">
              {{ value }}
              </button>
            </div>
            <!-- Sub-items para GRID -->
            <div v-if="selectedItemFormula && selectedItemFormula.type === 'GRID'" class="gridSubItems">
              <span class="subItemstitle">Sub-campos</span>
              <div v-for="subItem in selectedItemFormula.items" :key="subItem.tag" class="subItemContainer">
                <span class="subItemLabel">{{ subItem.label }}:</span>
                <button
                  class="valueButton subItemButton"
                  @click="addToFormula('PESO', subItem)"
                  :class="{ pesoUsed: isTagPesoInFormula(subItem.tag) }"
                >
                  PESO
                </button>
                <button
                  v-if="subItem.hasValue"
                  class="valueButton subItemButton"
                  @click="addToFormula('VALOR', subItem)"
                  :class="{ valorUsed: isTagValueInFormula(subItem.tag) }"
                >
                  VALOR
                </button>
              </div>
            </div>
              <!-- Sub-items para EXCELGRID -->
            <div v-if="selectedItemFormula && selectedItemFormula.type === FieldType.EXCELGRID" class="gridSubItems">
              <span class="subItemstitle">Sub-campos</span>
              <div class="row-flex subitem-grid-row">
                <!-- Columna inicial con labels -->
                <div class="column-flex subitem-grid-column">
                  <div class="column-header subitem-grid-header">&nbsp;</div>

                  <div v-for="item in selectedItemFormula.items[0].items" :key="item.tag" class="column-item subitem-grid-item">
                    {{ item.label }}
                  </div>
                </div>
                <!-- Resto de columnas -->
                <div v-for="subItem in selectedItemFormula.items" :key="subItem.tag" class="column-flex subitem-grid-column">
                  <div class="column-header subitem-grid-header">{{ subItem.label }}</div>
                  <div v-for="item in subItem.items" :key="item.tag" class="column-item subitem-grid-item">
                    <button
                      v-if="item.hasValue"
                      class="valueButton subItemButton"
                      @click="addToFormula('VALOR', item)"
                      :class="{ valorUsed: isTagValueInFormula(item.tag) }"
                    >
                      {{ item.tag }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- TEXTAREA FÓRMULA -->
      <div class="formulaTextareaContainer">
        <label class="selectorHeader">Fórmula ( {{ currentItem.tag }} )</label>
        <textarea v-model="formulaText" class="formulaTextarea" placeholder="Crea tu fórmula aquí..."></textarea>
      </div>
      <div class="footerDialog">
        <button class="refFormModalAsignBTN" @click="createFormula">
          Crear Fórmula
        </button>
        <div class="toastAdvise">
          <span class="succesToast" v-if="showSuccessToast">Fórmula creada correctamente</span>
          <div class="errorMessages">
            <span v-if="tagError" class="errorToast">Error: Hay tags no válidos en la fórmula.</span>
            <span v-if="valueError" class="errorToast">Error: Hay tags con .value que no corresponden a campos con
              valores.</span>
            <span v-if="circularReferenceError" class="errorToast">Error: Referencia circular detectada en la
              fórmula.</span>
            <span v-if="formulaError" class="errorToast">Error: La fórmula matemática no es válida.</span>
          </div>
        </div>
      </div>
    </div>
  </DPDialog>
</template>


<script setup lang="ts">
import { ref, toRefs, watch, Directive, onMounted, computed } from 'vue';
import DPDialog from '@/views/components/dialog.vue';
import Survey from '@/core/store/survey/surveyStore';
import { FieldType, FormulaAssignableItem, ITEMSWITHTAGS } from '@/core/store/survey/surveyDesignDtos';
import { Sh, TagCalculateItem, TagObject } from '@/core/sharedService';

const props = defineProps<{
  show: boolean
}>();

const { show } = toRefs(props);
const emit = defineEmits(['close']);

const { formulaAssignable, currentItem, currentDesign } = Survey();

const formulaText = ref(currentItem && currentItem.value && currentItem.value.content ? currentItem.value.content : "");
const selectedItemFormula = ref<FormulaAssignableItem | null>(null);
const fieldValues = ref<string[]>([]);
const showSuccessToast = ref(false);

// Refs para errores específicos
const tagError = ref(false);
const valueError = ref(false);
const circularReferenceError = ref(false);
const formulaError = ref(false);

watch(show, (newValue) => {
  if (newValue === false) return;
  searchTerm.value = "";
  formulaText.value = currentItem && currentItem.value && currentItem.value.content ? currentItem.value.content : "";
  if (formulaAssignable.value.length) {
    selectedItemFormula.value = formulaAssignable.value[0];
  }
  resetErrors();
});

watch(selectedItemFormula, (newValue) => {
  if (!newValue) return;
  fieldValues.value = [];
  // Verificamos si el item tiene Peso y/o Valor
  if (newValue.hasValue) {
    fieldValues.value.push("VALOR");
  }
  fieldValues.value.push("PESO");
});

const closeDialog = () => {
  emit('close');
};

const resetErrors = () => {
  tagError.value = false;
  valueError.value = false;
  circularReferenceError.value = false;
  formulaError.value = false;
};

const createFormula = () => {
  resetErrors(); // Resetea los errores antes de validar
  if (validate()) {
    currentItem.value.content = formulaText.value;
    showSuccessToast.value = true;

    // Cerrar el diálogo después de 3 segundos
    setTimeout(() => {
      closeDialog();
      showSuccessToast.value = false; // Resetea el estado del toast
    }, 1200);
  } else {
    return;
  }
};

const validate = (): boolean => {
  const tags: TagObject[] = Sh.extractTags(formulaText.value);

  // 1. Verificamos que los tags introducidos son correctos y existen como variables.
  const tagsInForms: string[] = tags.map(t => t.tag);
  const tagsDesign: string[] = currentDesign.value.items
    .filter(i => Object.keys(ITEMSWITHTAGS).includes(i.type.toString()))
    .flatMap(it => {
      const tags = [it.tag];
      if (it.type === FieldType.GRID && it.items) {
        tags.push(...it.items.map(subItem => subItem.tag));
      }
      if(it.type === FieldType.EXCELGRID && it.gridDesign){
        it.gridDesign.rows.forEach(row => tags.push(...row.tags));
      }
      return tags;
    });

  if (!tagsInForms.every(element => tagsDesign.includes(element))) {
    tagError.value = true;
    return false;
  }

  // 2. Verificamos que los tags con .value pueden acceder al value del item.
  const tagInFormWithValue: string[] = tags.filter(t => t.value).map(t2 => t2.tag);
  const tagsDesignWithValue: string[] = currentDesign.value.items
    .filter(i => Object.keys(ITEMSWITHTAGS).includes(i.type.toString()) && ITEMSWITHTAGS[i.type])
    .flatMap(it => {
      const tags = [it.tag];
      if (it.type === FieldType.GRID && it.items) {
        tags.push(...it.items.map(subItem => subItem.tag));
      }
      if(it.type === FieldType.EXCELGRID && it.gridDesign){
        it.gridDesign.rows.forEach(row => tags.push(...row.tags));
      }
      return tags;
    });

  if (!tagInFormWithValue.every(element => tagsDesignWithValue.includes(element))) {
    valueError.value = true;
    return false;
  }

  // 3. Verificamos referencias circulares.
  const formulas = currentDesign.value.items.filter(i => i.type == FieldType.FORMULE && i.id !== currentItem.value.id);
  if (formulas.length) {
    const tagForms: TagCalculateItem[] = [{ tag: currentItem.value.tag, tags: Sh.extractTags(formulaText.value) }];
    for (const f of formulas) {
      tagForms.push({ tag: f.tag, tags: Sh.extractTags(f.content) });
    }
    try {
      Sh.topologicalSortFormulas(tagForms);
    } catch (e) {
      circularReferenceError.value = true;
      console.log("Referencia circular");
      return false;
    }
  }

  // 4. Verificamos que matemáticamente la fórmula es coherente.
  if (!Sh.isFormulaValid(formulaText.value)) {
    formulaError.value = true;
    return false;
  }

  return true;
};

const addToFormula = (type: string, subItem?: FormulaAssignableItem) => {
  if (!selectedItemFormula.value) return;
  let textToAdd = '';
  const item = subItem || selectedItemFormula.value;
  if (type === 'PESO') {
    textToAdd = `{${item.tag}}`;
  } else if (type === 'VALOR') {
    textToAdd = `{${item.tag}.value}`;
  }
  //formulaText.value += textToAdd;
  formulaText.value +=  formulaText.value.endsWith('}') ? `+${textToAdd}` : textToAdd;
};

const isTagInFormula = (tag: string): boolean => {
  const tagInFormula = `{${tag}}`;
  const tagValueInFormula = `{${tag}.value}`;

  // Verificar si el tag principal está en la fórmula
  if (formulaText.value.includes(tagInFormula) || formulaText.value.includes(tagValueInFormula)) {
    return true;
  }

  // Si es un elemento GRID, verificar sus sub-items
  const item = formulaAssignable.value.find(i => i.tag === tag);
  if (item && item.type === 'GRID' && item.items) {
    return item.items.some(subItem =>
      formulaText.value.includes(`{${subItem.tag}}`) ||
      formulaText.value.includes(`{${subItem.tag}.value}`)
    );
  }

  return false;
};

const isTagPesoInFormula = (tag?: string): boolean => {
  if (!selectedItemFormula.value) return false;
  const tagToCheck = tag || selectedItemFormula.value.tag;
  const tagInFormula = `{${tagToCheck}}`;

  // Verificar solo el tag específico (principal o sub-item)
  return formulaText.value.includes(tagInFormula);
};

const isTagValueInFormula = (tag?: string): boolean => {
  if (!selectedItemFormula.value) return false;
  const tagToCheck = tag || selectedItemFormula.value.tag;
  const tagValueInFormula = `{${tagToCheck}.value}`;

  // Verificar solo el tag específico (principal o sub-item)
  return formulaText.value.includes(tagValueInFormula);
};


const vScrollIfNeeded: Directive = {
  mounted: (el) => checkOverflow(el),
  updated: (el) => checkOverflow(el)
};

function checkOverflow(el: HTMLElement) {
  const parent = el.parentElement;
  if (el.scrollWidth > parent.clientWidth) {
    el.classList.add('needs-scroll');
    const scrollAmount = el.scrollWidth - parent.clientWidth;
    el.style.setProperty('--scroll-amount', `${scrollAmount}px`);
  } else {
    el.classList.remove('needs-scroll');
    el.style.removeProperty('--scroll-amount');
  }
}

onMounted(() => {
  console.log("Todos los items disponibles:", formulaAssignable.value);
});

watch(selectedItemFormula, (newValue) => {
  if (!newValue) return;

  fieldValues.value = [];
  if (newValue.hasValue) {
    fieldValues.value.push("VALOR");
  }
  fieldValues.value.push("PESO");
});

const searchTerm = ref("");

const filteredFormulaAssignable = computed(() => {
  if (!searchTerm.value) {
    return formulaAssignable.value;
  }

  const searchLower = searchTerm.value.toLowerCase();

  return formulaAssignable.value.filter((item) => {
    const labelMatch = item.label.toLowerCase().includes(searchLower);
    const tagMatch = item.tag.toLowerCase().includes(searchLower);

    const subItemsMatch = item.type === 'GRID' && item.items
      ? item.items.some((subItem) =>
          subItem.tag.toLowerCase().includes(searchLower) ||
          subItem.label.toLowerCase().includes(searchLower)
        )
      : false;

    return labelMatch || tagMatch || subItemsMatch;
  });
});



</script>


<style scoped lang="scss">
@import url(./survey.scss);

.formulaCreatorContainer {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  max-width: 95vi;
  height: 90vh;
  padding: 20px;
  z-index: 10000000 !important;
  transform: translateY();
}

.formulaModalHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modalFormulaTitle {
  font-size: 30px;
  font-weight: 600;
  color: var(--primary-color);
}

.formulaSelector {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.itemContainer,
.valueButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 15px;
  margin-bottom: 4px;
  border-radius: 17px;
  background-color: var(--secondary-color);
  cursor: pointer;
}

.itemContainer:hover,
.valueButton:hover {
  background-color: var(--primary-color);
}

.itemLabelContainer {
  display: inline-block;
  max-width: 250px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.itemLabel,
.valueButton {
  display: inline-block;
  white-space: nowrap;
  color: var(--white);
  font-size: 15px;
  font-weight: 600;
}

.valueButton {
  border: none;
  width: 100%;
}

.itemLabel.needs-scroll {
  transition: transform 15s linear;
}

.itemLabelContainer:hover .itemLabel.needs-scroll {
  transform: translateX(calc(-1 * var(--scroll-amount)));
}

.itemTag {
  color: var(--white);
  font-size: 15px;
  font-weight: 600;
}

.selectorHeader {
  font-size: 16px;
  font-weight: 400;
  color: var(--darkgreen-text);
  margin-bottom: 10px;
}

.formulaSelectorFields,
.formulaSelectorValues {
  overflow-y: auto;
  text-overflow: ellipsis;
  width: 48%;
}

.formulaSelectorFields {
  margin-right: 2%;
}

.formulaSelectorValues {
  margin-left: 2%;
}

.fieldsListContainer,
.valuesListContainer {
  background-color: var(--compositor-color);
  padding: 14px;
  height: 475px;
  border-radius: 17px;
  overflow-y: auto;
}

.fieldsListContainer::-webkit-scrollbar,
.valuesListContainer::-webkit-scrollbar {
  width: 12px;
  background-color: #EDF5F3;

}

.fieldsListContainer::-webkit-scrollbar-thumb,
.valuesListContainer::-webkit-scrollbar-thumb {
  background-color: #12999D;
  border-radius: 6px;
}

.fieldsListContainer::-webkit-scrollbar-thumb:hover,
.valuesListContainer::-webkit-scrollbar-thumb:hover {
  background-color: #FAA476;

}

.fieldsListContainer::-webkit-scrollbar-track,
.valuesListContainer::-webkit-scrollbar-track {
  background-color: #B7DDDE;
}

.formulaTextareaContainer {
  width: 100%;
  margin-bottom: 20px;
}

.formulaTextarea {
  width: 100%;
  height: 120px;
  padding: 10px;
  border-radius: 17px;
  border: 1px solid var(--compositor-color);
  resize: none;
}

.footerDialog {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.toastAdvise {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.succesToast {
  background-color: #28a745;
  ;
  padding: 5px 10px;
  color: #FFF;
  font-weight: bold;
  border-radius: 5px;
}

.errorToast {
  background-color: #dc3545;
  padding: 5px 10px;
  color: #FFF;
  font-weight: bold;
  border-radius: 5px;
}

.selectedItems,
.pesoUsed,
.valorUsed {
  border: 4px solid var(--compositor-text);
}

.gridSubItems {
  margin-top: 10px;
  padding-top: 10px;
  text-align: center;
}

.subItemstitle {
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-color);
}

.subItemContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  margin-bottom: 5px;
}

.subItemLabel {
  flex: 1;
  padding: 0px 12px;
  font-size: 15px;
  color: var(--darkgreen-text);
}

.subItemButton {
  margin-left: 5px;
  padding: 2px 8px;
  font-size: 12px;
}

.tagSpan {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-color);
}
.subitem-grid-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  overflow-x: auto;
  font-size: 10px;
}

.subitem-grid-column {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

.subitem-grid-header {
  height: 40px;
  display: flex;
  align-items: center;
}

.subitem-grid-item {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-icon-container {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 16px;
  color: var(--compositor-text);
  z-index: 9999;
}

.filterInput {
  width: 100%;
  height: 30px;
  padding: 8px 35px 8px 15px;
  margin-bottom: 10px;
  border-radius: 15px;
  border: 1px solid var(--compositor-color);
  font-size: 16px;

  &:focus {
    outline: 2px solid var(--primary-color);
  }
}
</style>
