import loginPage from '@/views/Auth/login.vue';
import recoveryPage from '@/views/Auth/recovery.vue';
import entities from '@/views/entities/entities.vue';
import entityBase from '@/views/entities/entityBase.vue';
import formsEdit from '@/views/forms/edit/formsEdit.vue';
import formCreatedList from '@/views/forms/list/formCreatedList.vue';
import APIList from '@/views/forms/listAPI/APIList.vue';
import completeForm from '@/views/forms/complete/completeForm.vue'
import listAssigneds from '@/views/forms/listAssigneds/listAssigneds.vue';
import SurveyView from '@/views/survey/index.vue';
import formIndex from '@/views/forms/formsIndex.vue'
import layout from '@/views/layout.vue';
import usersList from '@/views/users/users.vue';
import userEdit from '@/views/users/userEdit.vue';
import { createRouter, createWebHistory } from 'vue-router';
import pruebas from '@/views/pruebas/pruebas.vue';
import { Sh } from '@/core/sharedService';
import { roles } from '@/shared/globaEnums';
const router = createRouter({
  history: createWebHistory("/"),
  
  routes: [
    
    {
      path: '/login',
      name: 'loginpage',
      
      component: loginPage
    },
    {
      path: '/recovery',
      name: 'recovery',
      component: recoveryPage
    },
/*     {
      path: '/pruebas',
      name: 'pruebas',
      component: pruebas
    }, */

    
    {
      path: '/',
      name: 'layout',
 /*      beforeEnter: (to, from, next) => {
        console.log("va a la ruta: ", to.fullPath);
        if( Sh.isNullOrEmptyOrZero(Sh.user.id)){
          next("/login");
          return;
        }
        if(["/list","/forms/listAssigneds" ].includes(to.fullPath)){
          next();
          return;
        }

        if(to.fullPath == "/"){
          switch(Sh.user.role){
            case roles.CLIENT:
            case roles.ROOT:
              next("/list");
              return;
              break;
            case roles.GROUP:
            case roles.ENTITY:

              next("/forms/listAssigneds");
              return;
              break;
          }
          return;
        }
      }, */
      component: layout ,
      children: [
        {
          path: '/forms',
          component: formIndex,
          children: [
            {
              path: '',
              redirect: to => {
                if(Sh.user.role == roles.ROOT || Sh.user.role == roles.CLIENT)
                  return { path: '/forms/listCreate' }
                else
                  return { path: '/forms/listAssigneds' }
              }
            },
            {
              path: 'listCreate',
              component: formCreatedList,
            },
            {
              path: 'listAPI',
              component: APIList,
            },
            {
              path: 'listAssigneds',
              component: listAssigneds,
            },
            {
              path: 'listAssigneds/:type',
              component: listAssigneds,
            },
            {
              path: 'view/:mode?',
              component: SurveyView,
            },
            
            {
              path: 'complete/:mode?',
              component: completeForm,
            },
                        
            {
              path: ':id',
              component: formsEdit,
            },            
            {
              path: ':id/:edit',
              component: formsEdit,
            },       
          ]
        },
        {
          path: '/',
          strict: true,
          component: entities,
        },
        {
          path: 'list',
          strict: true,
          component: entities,
        },
        {
          path: '/users',
          strict: true,
          component: usersList,
        },
        {
          path: '/users/edit/:id',
          strict: true,
          component: userEdit,
        },
        {
          path: '/users/:id',
          strict: true,
          component: userEdit,
        },
        {
          path: '/entity/:id',
          strict: true,
          component: entityBase,
        },
        {
          path: '/entity/:id/:edit',
          strict: true,
          component: entityBase,
        },
/*         {
          path: '/:type',
          strict: true,
          component: entities,
        }, */
   
      ]
    }
    
  ]
})

export default router
